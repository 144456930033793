// ** Redux Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Graphql Client
import client from "graphql/client";
import { jwtDefaultConfig } from "graphql/jwt";
import { RootState } from "redux/store";

const config = jwtDefaultConfig;

interface UserData {
  fullName: string;
  email: string;
}

interface ILoginPayload {
  accessToken: string;
  refreshToken: string;
  userData: UserData;
}

interface IUpdateUserPayload extends UserData {}

const initialUser = (): UserData | null => {
  const item = window.localStorage.getItem(config.storageUserDataKeyName);
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : null;
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
    accessToken: localStorage.getItem(config.storageTokenKeyName),
    refreshToken: localStorage.getItem(config.storageRefreshTokenKeyName),
    isLogged: !!localStorage.getItem(config.storageTokenKeyName),
  },
  reducers: {
    handleLogin: (state, action: PayloadAction<ILoginPayload>) => {
      client.resetStore();
      state.userData = action.payload.userData;
      state.accessToken = action.payload.accessToken;

      state.refreshToken = action.payload.refreshToken;
      localStorage.setItem(
        config.storageUserDataKeyName,
        JSON.stringify(action.payload.userData)
      );
      localStorage.setItem(
        config.storageTokenKeyName,
        action.payload.accessToken
      );
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        action.payload.refreshToken
      );
      window.location.href = "/";
    },
    handleUserUpdate: (state, action: PayloadAction<IUpdateUserPayload>) => {
      if (state.userData) {
        state.userData.fullName = action.payload.fullName;
        state.userData.email = action.payload.email;
        localStorage.setItem(
          config.storageUserDataKeyName,
          JSON.stringify(action.payload)
        );
      }
    },
    handleLogout: (state) => {
      state.userData = null;
      state.accessToken = null;
      state.refreshToken = null;
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem(config.storageUserDataKeyName);
      localStorage.removeItem(config.storageTokenKeyName);
      localStorage.removeItem(config.storageRefreshTokenKeyName);

      window.location.href = "/";
      client.resetStore();
    },
  },
});

export const { handleLogin, handleLogout, handleUserUpdate } =
  authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
