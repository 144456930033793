import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
//
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

//
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import client from "graphql/client";
import { Provider } from "react-redux";
import SpinnerComponent from "components/Spinner/Fallback-spinner";
import { store } from "redux/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const LazyApp = lazy(() => import("./App"));
root.render(
  <BrowserRouter basename="/">
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Suspense fallback={<SpinnerComponent />}>
          <LazyApp />
        </Suspense>
      </Provider>
    </ApolloProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
