// ** Logo
import logo from "images/logo/logo.svg";

const FallbackComponent = () => {
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <img className="fallback-logo" src={logo} alt="logo" />
        <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24" />
      </div>
    </div>
  );
};

export default FallbackComponent;
